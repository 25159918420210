<template>
    <CCard>
        <CCardHeader>
            <strong>&nbsp; &nbsp; User Profile </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
        </CCardHeader>
        <CCardBody>
            <CForm>

                <CInput
                        description="Customer's email"
                        label="Email address"
                        horizontal
                        id="email_address"
                        type="email"
                        :value="user.usr_email"
                        autocomplete="name"
                        placeholder="Email address"

                />

                <CInput
                        description="Customer's phone"
                        label="Phone number"
                        horizontal
                        id="phone_number"
                        :value="user.usr_phonenumber"
                        autocomplete="name"
                        type="tel"
                        placeholder="Phone Number"

                />

                <CInput
                        description="First Name"
                        label="First Name"f
                        id="first_name"
                        horizontal
                        :value="user.usr_firstname"
                        autocomplete="name"
                        placeholder="Text"

                />

                <CInput
                        description="Last Name"
                        label="Last name"
                        id="last_name"
                        horizontal
                        :value="user.usr_lastname"
                        autocomplete="name"
                        placeholder="Text"

                />


                <CInput
                        description="Zone"
                        label="Zone"
                        horizontal
                        :value="user.zone_name"
                        autocomplete="name"
                        placeholder="Text"
                        disabled

                />

                <CInput
                        description="Building"
                        label="Building"
                        horizontal
                        :value="user.bul_name"
                        autocomplete="name"
                        placeholder="Text"
                        disabled

                />

                <CInput
                        description="Unit"
                        label="Unit"
                        horizontal
                        :value="user.uni_name"
                        autocomplete="name"
                        placeholder="Text"
                        disabled

                />
                <div v-if="userUpdateFlag" class="lds-facebook"><div></div><div></div><div></div></div>

                <div v-else>
                    <CCardFooter>
                        <CButton @click="updateUserProfile()" type="submit" size="sm" color="primary">
                            <CIcon name="cil-check-circle"/>
                            Update Profile
                        </CButton>
                        <CButton @click="getUserData()" type="submit" size="sm" color="info">
                            <CIcon name="cil-check-circle"/>
                            Reset
                        </CButton>
                    </CCardFooter>
                </div>
                <CCardHeader>
                    <strong>Wireless Info
                    </strong>
                    <span style="color: red; padding-bottom: -30px; font-size: 10px"> (db) | </span>
                    <span style="color: darkblue; padding-bottom: -30px; font-size: 10px"> (ruckus) </span>
                    Edit &nbsp;
                    <div v-if="ctrlSyncFlagWLAN" class="lds-ripple"><div></div><div></div></div>
                    <CButton v-if="!ctrlSyncFlagWLAN" type="submit" size="sm" @click="wlanQuery()" color="info">
                        🔄 with controller
                    </CButton>
                </CCardHeader>
                <br>
                <CInput
                        label="SSID name"
                        description="User's network name"
                        type="text"
                        :value="user.wlan_ssid"
                        id="wlan_ssid"
                        horizontal
                        autocomplete="current-password"
                />

                <CInput
                        label="SSID passphrase"
                        description="User's network password"
                        type="text"
                        :value="user.wlan_passphrase"
                        id="wlan_passphrase"
                        horizontal
                        autocomplete="current-password"
                />
                <CInput
                        label="VLAN ID"
                        description="User's traffic is tagged with this value"
                        placeholder="Enter your password"
                        type="text"
                        :value="user.rvlan_vlanid"
                        horizontal
                        disabled
                />
                <p style="color: red"> {{user.vlan_status}} </p>
                <CCardFooter>
                <div v-if="wlanUpdateFlag" class="lds-ripple"><div></div><div></div></div>

                        <CButton @click="updateWlanNetowrk()" type="submit" size="sm" color="primary">
                            <CIcon name="cil-check-circle"/>
                            Update WLAN
                        </CButton>
                        <CButton @click="getUserData()" type="submit" size="sm" color="info">
                            <CIcon name="cil-check-circle"/>
                            Reset
                        </CButton>
                </CCardFooter>

                <CCardHeader>
                    <strong>AccessPoint Info
                    </strong>
                    <span style="color: red; padding-bottom: -30px; font-size: 10px"> (db) | </span>
                    <span style="color: darkblue; padding-bottom: -30px; font-size: 10px"> (ruckus) </span>
                    Edit &nbsp;
                    <div v-if="ctrlSyncFlagAP" class="lds-ripple"><div></div><div></div></div>
                    <CButton v-if="!ctrlSyncFlagAP" type="submit" size="sm" @click="apQuery()" color="info">
                        🔄 with controller
                    </CButton>
                </CCardHeader>
                <br>
                <CInput
                        label="MAC Address"
                        description="AP in User's Unit"
                        type="text"
                        :value="user.rap_mac"
                        horizontal
                        disabled
                />

                <CInput
                        label="AP Status"
                        description="tip: pull controller data to obtain correct AP Status"
                        type="text"
                        :value="user.rap_status"
                        horizontal
                        disabled
                />

                <CInput
                        label="AP Name"
                        description="AP name in database"
                        type="text"
                        :value="user.rap_name"
                        horizontal
                        autocomplete="current-password"
                />
                <CInput
                        label="AP IP"
                        description="AP IP address"
                        type="text"
                        :value="user.rap_ip"
                        horizontal
                        disabled
                />

                <CInput
                        label="AP Clients"
                        description="2.4G + 5G connected clients"
                        type="text"
                        :value="user.rap_clients"
                        horizontal
                        disabled
                />

                <CInput
                        label="WlanGroup24Id"
                        :description="user.rwg24_name"
                        type="text"
                        :value="user.rwg24_rid"
                        horizontal
                        disabled
                />

                <CInput
                        label="WlanGroup50Id"
                        :description="user.rwg50_name"
                        type="text"
                        :value="user.rwg50_rid"
                        horizontal
                        disabled
                />

                <CInput
                        label="Zone Name"
                        description="Zone where this AP belongs to"
                        type="text"
                        :value="user.zone_name"
                        horizontal
                        disabled
                />

            </CForm>
        </CCardBody>

    </CCard>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'User',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('users')
            })
        },
        data() {
            return {

                usersOpened: null,
                user: null,
                checkboxNames: ['Choose what should be available in table'],
                options: [],
                chb_status: [],
                userUpdateFlag: false,
                wlanUpdateFlag: false,
                ctrlSyncFlagAP: false,
                ctrlSyncFlagWLAN: false,
                vxur: false
            }
        },
        methods: {
            getUserData() {
                const id = this.$route.params.id
                axios.get(this.$baseURL + '/users/get_user?usr_id=' + id, {})
                    .then((res) => {
                        this.user = res.data;
                        this.$toast.success("User " +
                            "<b>" + this.user.usr_firstname + " " + this.user.usr_lastname + "</b>" + " Loaded from DB");
                    }).catch((error) => {
                    this.$toast.error("Faled to load data from db due to " + error);
                })

            },
            goBack() {
                this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
            },
            apQuery() {
                this.ctrlSyncFlagAP = true;
                axios.post(this.$baseURL + '/ruckus/ap/query?ap_mac=' + this.user.rap_mac, this.user)
                    .then((res) => {
                        this.user = res.data;
                        this.$toast.success("Successfully synced AP with controller");
                        this.ctrlSyncFlagAP = false;
                    }).catch((error) => {
                        this.ctrlSyncFlagAP = false;
                    this.$toast.error("Failed to sync AP due to " + error);
                    console.log(error);
                })
            },
            wlanQuery() {
                this.ctrlSyncFlagWLAN = true;
                axios.post(this.$baseURL + '/ruckus/wl/query?wlan_name=' + this.user.usr_email, this.user)
                    .then((res) => {
                        this.user = res.data;
                        this.$toast.success("Successfully synced WLAN with controller");
                        this.ctrlSyncFlagWLAN = false;
                    }).catch((error) => {
                    this.$toast.error("Failed to sync WLAN due to " + error);
                    console.log(error);
                    this.ctrlSyncFlagWLAN = false;
                })
            },
            getById(id) {
                return document.getElementById(id)._value
            },

            updateUserProfile() {

                this.userUpdateFlag = true;
                this.user.usr_email = this.getById('email_address');
                this.user.usr_phonenumber = this.getById('phone_number');
                this.user.usr_firstname = this.getById('first_name');
                this.user.usr_lastname = this.getById('last_name');


                axios.put(this.$baseURL + '/users/?usr_id=' + this.user.usr_id, this.user)
                    .then((res) => {
                        this.userUpdateFlag = !this.userUpdateFlag;
                        console.log(this.userUpdateFlag);
                        this.user = res.data;
                        this.$toast.success("User updated successfully.");

                    }).catch((error) => {
                    this.$toast.error(error.response.data.detail);
                    this.userUpdateFlag = !this.userUpdateFlag;
                })

            },
            updateWlanNetowrk() {
                this.wlanUpdateFlag = true;
                this.user.wlan_ssid = this.getById('wlan_ssid');
                this.user.wlan_passphrase = this.getById('wlan_passphrase');

                axios.put(this.$baseURL + '/users/wlan/?usr_id=' + this.user.usr_id, this.user)
                    .then((res) => {
                        this.wlanUpdateFlag = false;
                        this.user = res.data;
                        this.$toast.success("Wlan updated successfully");


                    }).catch((error) => {
                    this.wlanUpdateFlag = false;
                    this.$toast.error(error.response.data.detail);

                })
            }
        },
        mounted() {
            this.validateSession();
            this.getUserData();

        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }
    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }
    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }
    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>